import Prop1 from '../../assets/images/prop1.jpg';
import Prop2 from '../../assets/images/prop2.jpg';
import Prop3 from '../../assets/images/prop3.jpg';
const Properties =[
    {
        id:1,
        propertyTitle:"Beautiful Bunglow on sale",
        propertyLocation:"Bhaisepati , Lalitpur",
        propertyDescription:`Beautiful bunglow including garden with full 
                            13feet road access. Only 2km from ringroad.`,
        propertyFor:"Sale",
        propertyImage:Prop1,
        propertyType: "Commercial"
    },
    {
        id:2,
        propertyTitle:"Commercial Land for Sale",
        propertyLocation:"Bhaisepati , Lalitpur",
        propertyDescription:`Commercial Land at prime location. `,
        propertyFor:"rent",
        propertyImage:Prop2
    },{
        id:3,
        propertyTitle:"Top viewed appartment",
        propertyLocation:"Baneshwor , Kathmandu",
        propertyDescription:`Full Furnished at top where you can have night view `,
        propertyFor:"rent",
        propertyImage:Prop3
    },
]
export default Properties;