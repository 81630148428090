import React from 'react';
import { Box, Typography } from '@mui/material';

const ProjectsDetailComponent = ({ projectData }) => {
    
    return (
        <Box
            sx={{
                // backgroundColor: "red",
                mt: 4
            }}
        >
            <Typography variant='h4' fontWeight="600" 
                textAlign="center"
                sx={{
                    fontSize: { xs: "18px", sm: "28px", },
                    // backgroundColor:"red",
                    mb:1
                }}
            >
                {projectData.projectsTitle}
                <span
                    style={{
                        fontSize: "16px",
                        marginLeft: "6px",
                        fontStyle: "italic",
                        fontWeight: "500",
                        display: "block",
                        textTransform: "capitalize"
                    }}
                >{projectData.projectsStatus}</span>
            </Typography>
            <Typography variant='h4' fontWeight="500" 
                textAlign="center"
                fontFamily="cursive"
                sx={{
                    fontSize: { xs: "12px", sm: "20px", },
                    // backgroundColor:"red",
                    // mb:1
                }}
            >
              (  {projectData.projectsLocation} )</Typography>
           <Box
            sx={{
                // backgroundColor: "red",
                display: "flex",
                flexDirection: {xs: "column",sm: "row"},
                justifyContent: "center",
                alignItems: "center",
                gap: {xs:1,sm:3},
                mb: 2
            }}
           >
                <Typography gutterBottom variant='h6' fontWeight="300" fontFamily="Merriweather"
                    sx={{
                        fontSize: { xs: "14px", sm: "18px", },
                    }}
                >
                        Started On: <span
                            style={{
                                fontWeight: "200",
                                fontSize: "12px",
                            }}
                        >{projectData.startedOn}</span>
                </Typography>
                <Typography gutterBottom variant='h5' fontWeight="300" fontFamily="Merriweather"
                    sx={{
                        fontSize: { xs: "14px", sm: "18px", },
                    }}
                    >
                        Completed On: <span
                            style={{
                                fontWeight: "200",
                                fontSize: "12px",
                            }}
                        >{projectData.completedOn}</span>
                    </Typography>
           </Box>
            <Typography variant='body1' mb={1}>{projectData.description}</Typography>
        </Box>
    )
}

export default ProjectsDetailComponent;
