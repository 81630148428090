import React from 'react'
import { Container } from "@mui/material";
import ServicesPageComponent from '../components/page_components/services/services.page.component';
import Testimonials from '../components/testimonials/testimonials.components';
const ServicePage = ()=>{
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                // border: "1px solid red"
                backgroundColor: "yellow",
                // padding : "0px 10px"
            }}
        >
            <ServicesPageComponent />
            <Testimonials />
        </Container>
    );
}
export default ServicePage;