import React, { useState, useEffect } from 'react';
import { Box,CardMedia } from '@mui/material';
import Grid from '@mui/material/Grid2';
const RandomShapeImageListing = ({galleryData,setDataFromRandomShape}) => {
  const generateRandomStyle = () => {
    const borderRadius = `${Math.floor(Math.random() * 30)}% ${Math.floor(Math.random() * 20)}% ${Math.floor(Math.random() * 20)}% ${Math.floor(Math.random() * 30)}%`;
    const width = `${Math.floor(Math.random() * 150) + 150}px`;
    const height = `${Math.floor(Math.random() * 50) + 150}px`;
    return { borderRadius, width, height };
  };

  const [randomStyles, setRandomStyles] = useState([]);

  useEffect(() => {
    const styles = galleryData.map(() => generateRandomStyle());
    setRandomStyles(styles);
  }, [galleryData]);

  // Function to handle image click
  const handleImageClick = (image) => {
    setDataFromRandomShape(image);
  };

  return (
    <Box 
        sx={{ padding: 2 ,
            backgroundColor:'red',
    }}>
      {/* Image Gallery */}
      <Grid container spacing={2} justifyContent="center">
        {galleryData.map((image, index) => (
          <Grid item key={index}>
            <CardMedia
              component="img"
              image={image.galleryImage}
              alt={`Random Shape Image ${index + 1}`}
              onClick={() => handleImageClick(image)}  // Click event
              sx={{
                ...randomStyles[index],
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                cursor: "pointer",  // Change cursor to indicate clickable
              }}
            />
          </Grid>
        ))}
      </Grid>

    </Box>
  );
};

export default RandomShapeImageListing;
