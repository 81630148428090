import React from "react";

import {
  Box,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Grid2
} from "@mui/material";

function PropertyRequest() {
  // const [OpenHomeForm, setOpenHomeForm] = useState(false);
  // const [openApartmentForm, setOpenApartmentForm] = useState(false);

  // const handleApartmentForm = (e) => {
  //   e.preventDefault();
  //   setOpenApartmentForm(true);
  // };
  // const handleHomeForm = (e) => {
  //   e.preventDefault();
  //   setOpenHomeForm(true);
  // };

  return (
    <Box
      sx={{
        padding: "10px",
      }}
    >
      {/* Actual Form */}
      <Box sx={{ mt: "1rem" }}>
        <form>
          <Typography
            variant="h3"
            className="responsive_fontsize26"
            marginBottom="12px"
          >
            {" "}
            Location
          </Typography>
          <Grid2 item xs={12} md={6} lg={3}>
            <FormControl size="small" fullWidth>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(1,1fr)",
                    sm: "repeat(2,1fr)",
                    md: "repeat(2,1fr)",
                  },
                  gap: "12px",
                }}
              >
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-required"
                  label="Province"
                />

                <TextField
                  fullWidth
                  size="small"
                  id="outlined-required"
                  label="Zone"
                />

                <TextField
                  fullWidth
                  size="small"
                  id="outlined-required"
                  label="District"
                />

                <TextField
                  fullWidth
                  size="small"
                  id="outlined-required"
                  label="Municipality"
                />

                <TextField
                  fullWidth
                  size="small"
                  id="outlined-required"
                  label="Ward"
                />

                <TextField
                  fullWidth
                  size="small"
                  id="outlined-required"
                  label="Landmark"
                />
              </Box>
            </FormControl>
          </Grid2>

          <Typography
            variant="h3"
            className="responsive_fontsize26"
            margin="12px"
          >
            {" "}
            Property Detail
          </Typography>
          <Grid2
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1,1fr)",
                sm: "repeat(2,1fr)",
                md: "repeat(2,1fr)",
              },
              gap: "12px",
            }}
          >
            <FormControl
              size="small"
              fullWidth
              sx={{ maxWidth: { xs: "100%", md: "100%" } }}
            >
              <InputLabel id="demo-simple-select-label">Type</InputLabel>

              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={requestType}
                label="Type"
                type="text"
              >
                <MenuItem value="Commercial">commercial</MenuItem>
                <MenuItem value="Non Commercial">non commercial</MenuItem>
              </Select>
            </FormControl>
            <TextField
              // required
              size="small"
              id="outlined-required"
              sx={{ maxWidth: { xs: "100%", md: "100%" } }}
              label="Road Size"
            />

            <TextField
              //  required
              size="small"
              id="outlined-required"
              label="Area(sq.ft)"
              type="text"
              sx={{ maxWidth: { xs: "100%", md: "100%" } }}
            />

            <FormControl
              size="small"
              fullWidth
              sx={{ maxWidth: { xs: "100%", md: "100%" } }}
            >
              <InputLabel>Facing</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Facing"
                // onChange={handleChange}
              >
                <MenuItem value="North">North</MenuItem>
                <MenuItem value="South">South</MenuItem>
                <MenuItem value="East">East</MenuItem>
                <MenuItem value="West">West</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              size="small"
              fullWidth
              sx={{ maxWidth: { xs: "100%", md: "100%" } }}
            >
              <InputLabel>Sewage</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Sewage"
                // onChange={handleChange}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              size="small"
              fullWidth
              sx={{ maxWidth: { xs: "100%", md: "100%" } }}
            >
              <InputLabel>Sewage</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Sewage"
                // onChange={handleChange}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              size="small"
              fullWidth
              sx={{ maxWidth: { xs: "100%", md: "100%" } }}
            >
              <InputLabel>Electricity</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={WardNumber}
                label="Electricity"
                type="text"
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              size="small"
              fullWidth
              sx={{ maxWidth: { xs: "100%", md: "100%" } }}
            >
              <InputLabel>Drinking Water</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={WardNumber}
                label="Drinking Water"
                type="text"
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              size="small"
              fullWidth
              sx={{ maxWidth: { xs: "100%", md: "100%" } }}
            >
              <InputLabel>Urgent</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={urgency}
                label="Urgent"
                type="text"
              >
                <MenuItem value="Very Urgent">Very Urgent</MenuItem>
                <MenuItem value="Not Urgent">not Urgent</MenuItem>
              </Select>
            </FormControl>
          </Grid2>
          <Typography
            variant="h2"
            className="responsive_fontsize26"
            margin="12px"
          >
            {" "}
            Price Range
          </Typography>
          <Grid2
            sx={{
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <TextField
              id="standard-basic"
              size="small"
              sx={{ maxWidth: { xs: "30%", md: "7rem" }, margin: "0 10px" }}
              variant="standard"
            />
            <InputLabel>To</InputLabel>
            <TextField
              id="standard-basic"
              size="small"
              variant="standard"
              sx={{ maxWidth: { xs: "30%", md: "7rem" }, margin: "0 10px" }}
            />
          </Grid2>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <FormControl
              size="small"
              sx={{
                width: { xs: "90%", sm: "70%", md: "50%" },
              }}
            >
              <TextField
                id="description"
                multiline
                rows={3}
                placeholder="Description"
                sx={{
                  backgroundColor: "#f9f9f9",
                  borderRadius: "10px",
                }}
              />
            </FormControl>
          </Box>

          <Grid2
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: "secondary.main", color: "white" }}
            >
              Request
            </Button>
          </Grid2>
        </form>
      </Box>
    </Box>
  );
}

export default PropertyRequest;
