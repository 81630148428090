import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

const BlogCard = ({ title, author, date, excerpt, image }) => {
  return (
    <Card sx={{ maxWidth: 345, margin: "10px" }}>
      <CardMedia component="img" height="140" image={image} alt={title} />
      {/* <CardHeader
        title={title}
        subheader={`${author} - ${date}`}
      /> */}

      <CardContent>
        <Typography
          variant="h6"
          fontFamily="Roboto-Serif"
          sx={{
            fontSize: { xs: "16px", sm: "22px" },
            fontWeight: "600",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          fontFamily="Roboto-Serif"
          sx={{
            fontSize: { xs: "14px", sm: "18px" },
            fontWeight: "500",
          }}
        >
          {`${author} - ${date}`}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          fontFamily="Times New Roman"
          sx={{
            fontSize: { xs: "12px", sm: "16px" },
            textAlign: "justify",
          }}
        >
          {excerpt}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BlogCard;
