import React from 'react';
import { Container, Box, useMediaQuery, useTheme,Typography } from "@mui/material";
import ProjectsImage from '../../../assets/images/more/projects.jpg';
import ProjectsImage1 from '../../../assets/images/more/pp.jpg';
import ProjectsData from '../../projects/projects.data';
import ProjectsDetailComponent from './projects_detail.component';

const ProjectsPageComponent = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check for sm and xs screen

    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                backgroundColor: "primary.fifth",
                padding: "0px",
                // border: "1px solid black",
            }}
            >
            <Box
                sx={{
                    height: {xs:"300px",sm:"400px",md:"600px"},
                    width: "100%",
                    backgroundImage: isSmallScreen? `url(${ProjectsImage})`:`url(${ProjectsImage1})`,
                    backgroundPosition: isSmallScreen ? "top" : "center",  // Adjust position
                    backgroundSize: isSmallScreen ? "cover" : "cover",    // Adjust size
                    backgroundRepeat: "no-repeat", 
                    backgroundColor: "red",
                    boxShadow: {xs:"inset 0px -80px 20px rgba(0,0,0,0.5)",sm:"inset 0px -200px 20px rgba(0,0,0,0.5)"},
                }}
            >

            </Box>
            <Container
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: 'auto',
                    // border: "1px solid black",
                    padding: { xs: "10px 10px 30px 10px", sm: "20px 10px 40px 10px", lg: "20px 0px 60px 0px" },
                    position: "relative",
                    // backgroundColor: "yellow"
                }}
            >
                <Box 
                    sx={{ 
                        width: { xs: "100%" } ,
                        // backgroundColor: "red",
                        position: "absolute",
                        top: {xs:"-60px",sm:"-120px",md:"-180px"},
                        // filter: "blur(10px)"
                        }}>
                    <Typography 
                        variant="h5" 
                        fontWeight="700" 
                         
                        textAlign="center"
                        sx={{
                            fontSize: { xs: "24px", sm: "28px", md: "38px", lg: "48px" },
                            color: "#FFFFFF",
                            textTransform: "uppercase",
                        }}
                    >
                        Projects
                    </Typography>
                    <Typography
                        variant="h5" 
                        fontWeight="500" 
                        gutterBottom 
                        textAlign="center"
                        fontFamily="cursive"
                        sx={{
                            fontSize: { xs: "14px", sm: "18px", md: "24px",},
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                        }}
                    >Proud to showcase our dedication.</Typography>
                    
                </Box>
                <Box
                    sx={{
                        height:"auto",
                        width: "100%",
                        // backgroundColor: "green",
                        display: "flex",
                        flexDirection: "column",
                        // gap:5,
                    }}
                >
                    {
                        ProjectsData.map((pData,index)=>
                            <ProjectsDetailComponent projectData={pData} key={index}/>
                        )
                    }
                </Box>
            </Container>
        </Container>
    );
};

export default ProjectsPageComponent;
