import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

function PropertyCard({ imageUrl }) {
    // if (!imageUrl || typeof imageUrl !== "string") {
    //     return <div>No Image Available</div>; // Fallback if URL is invalid
    //   }
  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "480px", // Default height, adjust as needed
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          image={imageUrl}
          alt="Property Image"
          sx={{
            width: "100%",
            // height: "100%",
            objectFit: "cover", // Ensure the image covers the container without distortion
            height: {
              xs: "300px", // Responsive height for small devices
              sm: "350px",
              md: "400px",
              lg: "480px",
            },
          }}
        />
      </CardActionArea>
    </Card>
  );
}

export default PropertyCard;
