import React from 'react';
import { Card, CardContent, Typography, Box, CardHeader, Avatar } from '@mui/material';

const TestimonialCard = ({ name, location, post, date, feedback }) => {
  return (
    <Box sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // padding: "1rem",
        borderRadius: 1,
        marginBottom: '1rem',
        mx: { xs: 0, sm: 2, md: 3, lg: 4 }  // Adjust horizontal margins responsively
      }}>
    <Card sx={{ maxWidth: 345, margin: '10px' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ backgroundColor: "#C77F05" }}>
            {name.charAt(0)}
          </Avatar>
        }
        title={name}
        subheader={`${location} - ${post}`}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {feedback}
        </Typography>
        <Box sx={{ textAlign: "right", marginTop: "10px" }}>
          <Typography variant="caption" display="block" color="text.secondary">
            {date}
          </Typography>
        </Box>
      </CardContent>
    </Card>
    </Box>
  );
}

export default TestimonialCard;
