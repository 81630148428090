import React from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Grid2,
} from "@mui/material";

function PropertyRequest() {
  // const [openApartmentForm, setOpenApartmentForm] = useState(false);
  // const [OpenLandForm, setOpenLandForm] = useState(false);

  // const handleApartmentForm = (e) => {
  //   e.preventDefault();
  //   setOpenApartmentForm(true);
  // };

  // const handleLandForm = (e) => {
  //   e.preventDefault();
  //   setOpenLandForm(true);
  // };

  return (
    <Box
      sx={{
        padding: "10px",
      }}
    >
      {/* Form Container */}
      <Box sx={{ mt: "1rem" }} className="responsive_fontsize16">
        <form>
          {/* Location Section */}
          <Box>
            <Typography
              variant="h3"
              className="responsive_fontsize26"
              marginBottom="12px"
            >
              Location
            </Typography>
            <Grid2 item xs={12} md={6} lg={3}>
              <FormControl size="small" fullWidth>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      xs: "repeat(1,1fr)",
                      sm: "repeat(2,1fr)",
                      md: "repeat(2,1fr)",
                    },
                    gap: "12px",
                  }}
                >
                  {/* Location Fields */}
                  {["Province", "Zone", "District", "Municipality", "Ward", "Landmark"].map((label) => (
                    <TextField
                      key={label}
                      fullWidth
                      size="small"
                      id={`outlined-${label}`}
                      label={label}
                    />
                  ))}
                </Box>
              </FormControl>
            </Grid2>
          </Box>

          {/* Property Detail Section */}
          <Typography
            variant="h3"
            className="responsive_fontsize26"
            margin="12px"
          >
            Property Detail
          </Typography>

          <Grid2
            item
            xs={12}
            md={6}
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1,1fr)",
                sm: "repeat(2,1fr)",
                md: "repeat(2,1fr)",
              },
              gap: "12px",
            }}
          >
            {/* Property Fields */}
            <FormControl size="small" fullWidth>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select labelId="type-select-label" id="type-select" label="Type">
                <MenuItem value="Commercial">Commercial</MenuItem>
                <MenuItem value="Residential">Residential</MenuItem>
              </Select>
            </FormControl>

            {["Total Floor", "Area(sq.ft)", "BHK"].map((label) => (
              <TextField
                key={label}
                size="small"
                id={`outlined-${label}`}
                label={label}
              />
            ))}

            {/* Select Fields */}
            <FormControl size="small" fullWidth>
              <InputLabel>Facing</InputLabel>
              <Select label="Facing">
                <MenuItem value="North">North</MenuItem>
                <MenuItem value="South">South</MenuItem>
                <MenuItem value="East">East</MenuItem>
                <MenuItem value="West">West</MenuItem>
              </Select>
            </FormControl>

            <TextField size="small" id="outlined-required" label="Property Age" />

            <FormControl size="small" fullWidth>
              <InputLabel>Furnishing</InputLabel>
              <Select label="Furnishing">
                <MenuItem value="Non">Non</MenuItem>
                <MenuItem value="Semi">Semi</MenuItem>
                <MenuItem value="Full">Full</MenuItem>
              </Select>
            </FormControl>

            <TextField size="small" id="outlined-required" label="Road Size" />

            <FormControl size="small" fullWidth>
              <InputLabel>Urgency</InputLabel>
              <Select label="Urgency">
                <MenuItem value="Very Urgent">Very Urgent</MenuItem>
                <MenuItem value="Not Urgent">Not Urgent</MenuItem>
              </Select>
            </FormControl>
          </Grid2>

          {/* Price Range Section */}
          <Typography variant="h2" className="responsive_fontsize18" margin="12px">
            Price Range
          </Typography>

          <Grid2
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <TextField
              id="price-from"
              size="small"
              variant="standard"
              sx={{ maxWidth: { xs: "30%", md: "7rem" }, margin: "0 10px" }}
            />
            <InputLabel>To</InputLabel>
            <TextField
              id="price-to"
              size="small"
              variant="standard"
              sx={{ maxWidth: { xs: "30%", md: "7rem" }, margin: "0 10px" }}
            />
          </Grid2>

          {/* Description Section */}
       

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <FormControl
              size="small"
              sx={{
                width: { xs: "90%", sm: "70%", md: "50%" },
              }}
            >
              <TextField
                id="description"
                multiline
                rows={3}
                placeholder="Description"
                sx={{
                  backgroundColor: "#f9f9f9",
                  borderRadius: "10px",
                }}
              />
            </FormControl>
          </Box>

          {/* Submit Button */}
          <Grid2 item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: "secondary.main", color: "white" }}
            >
              Request
            </Button>
          </Grid2>
        </form>
      </Box>
    </Box>
  );
}

export default PropertyRequest;
