import React from "react";
import { Box, Typography,} from "@mui/material";

import Intro from "../../assets/byd_logo.png";
const RightContainer = ()=>{
    return(
        <Box
            sx={{
                maxWidth: { xs: "100%", md: "50%" },
                width: "100%",
                // border: "1px solid red"
            }}
        >
            <Box
                sx={{
                // backgroundColor: "blue",
                display: "flex",
                justifyContent: { xs: "center", md: "left" },
                }}
            >
                  {/* <OrangeDivider sx={{backgroundColor: "red"}}> */}
                  <Typography
                    variant="h5"
                    fontWeight="700"
                    sx={{
                      fontSize: {
                        xs: "24px",
                        sm: "28px",
                        md: "38px",
                        lg: "48px",
                      },
                      color: "rgba(0,0,0,1)",
                      textTransform: "uppercase",
                      //   mt:{xs:"12px",sm:"20px"},
                    }}
                    // className="responsive__fontsize32"
                  >
                    About Us
                  </Typography>
                </Box>

                <Box sx={{ py: "1.5rem" }}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        // backgroundColor:"red",
                      }}
                    >
                      <Box
                        sx={{
                          height: {xs:"60px",md:"70px"},
                          width: {xs:"100px"},
                        //   backgroundColor: "primary.firth",
                          backgroundImage: `url(${Intro})`,
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        //   border:"1px solid black"
                        //   padding:"5px"
                        //   borderRadius: "50%",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // border: "1px solid red",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography fontFamily="Roboto-Serif"
                          sx={{ 
                        fontSize: {xs:"16px",sm:"22px"},
                          fontWeight: "600" }}
                          className="responsive__fontsize26"
                        >
                          B.Y.D Real Estate And Construction P.V.T
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography fontFamily="Roboto-Serif"
                    //   className="responsive__fontsize16"
                      sx={{
                        textAlign: "justify",
                        mb: 1,
                        fontSize: {xs:"14px",sm:"18px"},
                        fontWeight: "500"
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Magni error, nobis maxime quas voluptates necessitatibus
                      pariatur quasi aliquid repellat doloremque quos, explicabo
                      minus nam, totam tempora voluptatem dolores cum
                      voluptatibus vero vitae iste rerum placeat. Neque eius
                      iusto illum, adipisci dicta, saepe qui consequuntur facere
                      ipsum commodi, aperiam enim optio!
                    </Typography>
                    <Typography
                    fontFamily="Roboto-Serif"
                    //   className="responsive__fontsize16"
                      sx={{
                        textAlign: "justify",
                        mb: 1,
                        fontSize: {xs:"14px",sm:"18px"},
                        fontWeight: "500"
                      }}>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Porro, quaerat fugit expedita est animi itaque? Dolore
                      eius tempore vel veniam labore magnam, doloremque nostrum,
                      voluptas dolor possimus totam ut pariatur.
                    </Typography>
                    <Typography
                    fontFamily="Roboto-Serif"
                    //   className="responsive__fontsize16"
                      sx={{
                        textAlign: "justify",
                        mb: 1,
                        fontSize: {xs:"14px",sm:"18px"},
                        fontWeight: "500"
                      }}
                    >
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Porro, quaerat fugit expedita est animi itaque? Dolore
                      eius tempore vel veniam labore magnam, doloremque nostrum,
                      voluptas dolor possimus totam ut pariatur.
                    </Typography>
                    <Typography
                   fontFamily="Roboto-Serif"
                   //   className="responsive__fontsize16"
                     sx={{
                       textAlign: "justify",
                       mb: 1,
                       fontSize: {xs:"14px",sm:"18px"},
                       fontWeight: "500"
                     }}
                    >
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Porro, quaerat fugit expedita est animi itaque? Dolore
                      eius tempore vel veniam labore magnam, doloremque nostrum,
                      voluptas dolor possimus totam ut pariatur.
                    </Typography>
                    <Typography
                    fontFamily="Roboto-Serif"
                    //   className="responsive__fontsize16"
                      sx={{
                        textAlign: "justify",
                        mb: 1,
                        fontSize: {xs:"14px",sm:"18px"},
                        fontWeight: "500"
                      }}
                    >
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Porro, quaerat fugit expedita est animi itaque? Dolore
                      eius tempore vel veniam labore magnam, doloremque nostrum,
                      voluptas dolor possimus totam ut pariatur.
                    </Typography>
                  </Box>
            </Box>
        </Box>
    );
}
export default RightContainer;