import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./layout/navbar/navbar.layout";
import FooterBar from "./layout/footer/footer.layout";
function RootLayout() {
  return (
    <>
      <NavBar />
      <Outlet />
      <FooterBar />
    </>
  );
}

export default RootLayout;