import prop1 from '../../../assets/images/prop1.jpg'
import prop2 from '../../../assets/images/prop2.jpg'
import prop3 from '../../../assets/images/prop3.jpg'
import prop4 from '../../../assets/images/prop4.jpg'
import prop5 from '../../../assets/images/prop5.jpg'
import prop6 from '../../../assets/images/prop6.jpg'
const GalleryData = [
    {
        id:1,
        galleryTitle: "Beautiful House ",
        galleryImage: prop1
    },
    {
        id:2,
        galleryTitle: "Beautiful House ",
        galleryImage: prop2
    },
    {
        id:3,
        galleryTitle: "Beautiful House ",
        galleryImage: prop3
    },
    {
        id:4,
        galleryTitle: "Beautiful House ",
        galleryImage: prop4
    },
    {
        id:5,
        galleryTitle: "Beautiful House ",
        galleryImage: prop5
    },
    {
        id:6,
        galleryTitle: "Beautiful House ",
        galleryImage: prop6
    },
]
export default GalleryData;