import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8f6B29",
      second: "#DF9F28", // Note: 'second' is not standard, it's custom.
      third: "#ffa500",
      fourth: "#fff2df",
      fifth: "#fffefa",
      sixth:"#fbf8f2",
    },
    secondary: {
      main: "rgba(143,107,42,1)",
      second:"rgba(254,224,141,1)",
      third: 'rgba(213,160,30,.5) 50%'
    },
    info: {
      main: "#9e1db5",
    },
    warning: {
      main: "#ced127",
    },
    error: {
      main: "#ec4034",
    },
    accent: {
      main: "#000000", // Custom color for accentMain
      secondary: "#ffffff", // Custom color for accentSecondary
    },
  },
  typography: {
    fontFamily: "Roboto Serif",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 950,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;