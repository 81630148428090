import React from "react";
import { Box } from "@mui/material";

import ToggleFilter from "./properties.filter.toggle";
import CustomFilterPanelContent from "./properties.filter";

function PropertyFilterMain() {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <ToggleFilter />
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <CustomFilterPanelContent />
        </Box>
      </Box>
    </div>
  );
}

export default PropertyFilterMain;
