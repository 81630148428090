
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from "@mui/icons-material/Pinterest";
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from "@mui/icons-material/YouTube";
const socialMediaIcons = [
    { 
        component: FacebookIcon, label: 'Facebook',link:'' },
    { component: PinterestIcon, label: 'Pinterest',link:'' },
    { component: InstagramIcon, label: 'Instagram',link:'' },
    { component: YouTubeIcon, label: 'You Tube',link:'' }
  ];
export default socialMediaIcons;