import React from "react";
import Slider from "react-slick";
import { Box } from '@mui/material'
import PropertyList from './propertyList';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Pic from './propertyPic';
// import SearchBar from './searchBar';

function SampleNextArrow(props) {
  const {style, onClick } = props;
  return (
    <div 
      className={'slick-next'}
      style={{
        ...style,
        // style for arrow in right
        backgroundColor:"#292C6A",   
        flexDirection: "column",
        justifyContent: "center",
        alignItems:"center",
        borderRadius: "50%",
        paddingTop: "2.5px",
        // border:"1px solid red",
        
      }}
      onClick={onClick}
    /> 
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, 
        // style for arrow in left
        backgroundColor: "#292C6A",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems:"center",
        borderRadius: "50%",
        paddingTop: "2.5px"
      }}
      onClick={onClick}
    />
  );
}

function Duplicate() {
  const settings = { 
      dots: false,
      infinite: true,
      // speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      pauseOnHover: true,
      focusOnSelect: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 1050,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
  };
return (
  <Box className="center-carousel" sx={{padding:'10px 50px',
  justifyContent:"center"}}>   
    <Slider {...settings}>
    {PropertyList.map((item)=>(
            <div key={item.id}>
                <Pic
                imageUrl={item.imageUrl}
                />
            </div>
        ))}
    </Slider>
  </Box>
);
}

export default Duplicate;
