// App.js or the main component file

import React, { useState } from "react";
import DetailForm from "./detail_form";
import RequestIconComponents from "./request_form";
import { Dialog, Box, Typography } from "@mui/material";

function FormContainer() {
  const [openRequestDialog, setOpenRequestDialog] = useState(false);

  // Handle the form submission from DetailForm
  const handleDetailFormSubmit = () => {
    setOpenRequestDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenRequestDialog(false);
  };

  return (
    <Box>
      {/* Display the Detail Form */}
      <Typography variant="h4" sx={{ textAlign: "center", my: 2 }}>
        Fill in Your Details
      </Typography>
      <DetailForm onSubmit={handleDetailFormSubmit} />

      {/* Dialog to display RequestIconComponents */}
      <Dialog
        open={openRequestDialog}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        {/* Render the RequestIconComponents */}
        <RequestIconComponents />
      </Dialog>
    </Box>
  );
}

export default FormContainer;
