 const PropertyData=[
    {
        id: 1,
        imageUrl:'https://images.unsplash.com/photo-1523217582562-09d0def993a6?q=80&w=580&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        location: "Bhaisepati",
        price: "$50000",
        title: "House for rent",
        name: "Adil",
        date: "2 months ago",
        propertyType:"Single Family Homes",
        totalRooms:'10',
        bedRooms:'4',
        builtYear:'2000',
        landArea:'3 aana',
        map:'https://maps.app.goo.gl/dMZP4FEFEm5YT76t5'
    },
    {
        id: 2,
        imageUrl:' https://images.unsplash.com/photo-1605276373954-0c4a0dac5b12?q=80&w=870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        location: "Sanepa",
        price: "$80000",
        title: "land for rent",
        name: "ashika",
        status: "rent",
        date: "4 weeks ago",
        propertyType:"Rent",
        totalRooms:'6',
        bedRooms:'3',
        builtYear:'2002',
        landArea:'4 aana',
        map:'https://maps.app.goo.gl/6LCunEXerNJ22HJ4A'
    },
    {
        id: 3,
        imageUrl:'https://images.unsplash.com/photo-1505843513577-22bb7d21e455?q=80&w=1032&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        location: "Bhaisepati",
        price: "$20000",
        title: "House for sale",
        name: "samima",
        status: "sale",
        date: "1 months ago",
        propertyType:"Apartments",
        totalRooms:'7',
        bedRooms:'4',
        builtYear:'1999',
        landArea:'2 aana',
        map:'https://maps.app.goo.gl/XBU8ZBCTFafJZ5zb9'
    },
    {
        id: 4,
        imageUrl:'https://images.unsplash.com/photo-1576941089067-2de3c901e126?q=80&w=978&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        location: "Kusunti",
        price: "$80000",
        title: "House for sale",
        name: "sam",
        date: "3 months ago",
        propertyType:"Land",
        totalRooms:'5',
        bedRooms:'2',
        builtYear:'2001',
        landArea:'2 aana',
        map:'https://maps.app.goo.gl/pB7gVnVEVqc2JTig7'
    },
    {
        id: 5,
        imageUrl:'https://images.unsplash.com/photo-1580587771525-78b9dba3b914?q=80&w=774&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        location: "Lalitpur",
        price: "$30000",
        title: "land for rent",
        name: "praju",
        date: "7 months ago",
        propertyType:"Land",
        totalRooms:'8',
        bedRooms:'3',
        builtYear:'1998',
        landArea:'5 aana',
        map:'https://maps.app.goo.gl/DU9JzQWtXr7AW1eT7'
    },
    {
        id: 6,
        imageUrl:'https://images.unsplash.com/photo-1558036117-15d82a90b9b1?q=80&w=870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        location: "Tahachal",
        price: "$70000",
        title: "land for sale",
        name: "ram",
        date: "9 months ago",
        propertyType:"House",
        totalRooms:'5',
        bedRooms:'2',
        builtYear:'2003',
        landArea:'3 aana',
        map:'https://maps.app.goo.gl/2KDCva8NY5T4SpQCA'
    },
    {
        id: 7,
        imageUrl:'https://images.unsplash.com/photo-1512917774080-9991f1c4c750?q=80&w=870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        location: "Bhaisepati",
        price: "$900000",
        title: "House for rent",
        name: "sarita",
        date: "3 months ago",
        propertyType:"Apartment",
        totalRooms:'9',
        bedRooms:'5',
        builtYear:'2000',
        landArea:'3 aana',
        map:'https://maps.app.goo.gl/cMnyUfLizLHxgoxh9'
    },
    {
        id: 8,
        imageUrl:'https://images.unsplash.com/photo-1576941089067-2de3c901e126?q=80&w=978&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        location: "Sanepa",
        price: "$990000",
        title: "House for rent",
        name: "rina",
        date: "10 months ago",
        propertyType:"Other property Types",
        totalRooms:'5',
        bedRooms:'3',
        builtYear:'2001',
        landArea:'3 aana',
        map:'https://maps.app.goo.gl/UNsK96zffxAaJ1LWA'
    },

] 

export default PropertyData;
