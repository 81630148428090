import React from 'react'
import { Container } from "@mui/material";
import ProjectsPageComponent from '../components/page_components/projects/projects.page.component';
import Testimonials from '../components/testimonials/testimonials.components';
const ProjectsPage = ()=>{
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                // border: "1px solid red"
                backgroundColor: "yellow",
                // padding : "0px 10px"
            }}
        >
            <ProjectsPageComponent />
            <Testimonials />
        </Container>
    );
}
export default ProjectsPage;