import Prop1 from '../../assets/images/prop1.jpg';
import Prop2 from '../../assets/images/prop2.jpg';
import Prop3 from '../../assets/images/prop3.jpg';
const dummyText = `Lorem Ipsum is simply dummy text of the printing and typesetting 
    industry. Lorem Ipsum has been the industry's standard dummy text ever since the 
    1500s, when an unknown printer took a galley of type and scrambled it to make a 
    type specimen book. It has survived not only five centuries, but also the leap into 
    electronic typesetting, remaining essentially unchanged. It was popularised in the 
    1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more 
    recently with desktop publishing software like Aldus PageMaker including versions of 
    Lorem Ipsum.`
const projects =[
    {
        id:1,
        startedOn:"2081-03-05",
        completedOn: "",
        description:dummyText,
        projectsTitle:"Full Furnished 5bhk ",
        projectsLocation:"Bhaisepati , Lalitpur",
        projectsImage:Prop1,
        projectsType: "Commercial",
        projectsStatus:"ongoing"
    },
    {
        id:2,
        startedOn:"2081-03-05",
        completedOn: "2081-06-07",
        description:dummyText,
        projectsTitle:"Commercial Land Sold",
        projectsLocation:"Ekantakuna-13 , Lalitpur",
        projectsImage:Prop2,
        projectsType:`Commercial `,
        projectsStatus:"completed",
    },{
        id:3,
        startedOn:"2081-03-05",
        completedOn: "2081-06-07",
        description:dummyText,
        projectsTitle:"10 store projects",
        projectsLocation:"Baneshwor , Kathmandu",
        projectsImage:Prop3,
        projectsType:`Residential`,
        projectsStatus:"completed",
    },{
        id:4,
        startedOn:"2081-03-05",
        completedOn: "",
        description:dummyText,
        projectsTitle:"Full Furnished 5bhk ",
        projectsLocation:"Bhaisepati , Lalitpur",
        projectsImage:Prop1,
        projectsType: "Commercial",
        projectsStatus:"ongoing"
    },{
        id:5,
        startedOn:"2081-03-05",
        completedOn: "2081-06-07",
        description:dummyText,
        projectsTitle:"Commercial Land Sold",
        projectsLocation:"Ekantakuna-13 , Lalitpur",
        projectsImage:Prop2,
        projectsType:`Commercial `,
        projectsStatus:"completed",
    }
]
export default projects;