import React from 'react';
import Grid from "@mui/material/Grid2";
import ProjectsCard from "./projects.cards";

const ProjectsCardListing = ({ProjectsData}) => {
    return (
        <Grid container spacing={4}
            sx={{
                justifyContent: "center",
                height: "auto",
                pt: "20px",
            }}
        >
            {
                ProjectsData.slice(0, 4).map((data, index) => (
                    <ProjectsCard projects={data} key={index} />
                ))
            }
        </Grid>
    );
};

export default ProjectsCardListing;
