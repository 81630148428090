import React from "react";
import { Card, Box, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const ProjectsCard = ({ projects }) => {
  return (
    <Grid item xs={12} sm={3} md={4}>
      <Card
        sx={{
          position: "relative",
          height: { xs: "200px", sm: "240px" },
          display: "flex",
          flexDirection: "column", // Make the card content stay at the bottom
          justifyContent: "flex-end",
          overflow: "hidden",
          borderRadius: 0,
          maxWidth:{xs:"340px",md:"260px",lg:"340px"},
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Optional box shadow
        }}
      >
        {/* Background with reduced opacity */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${projects.projectsImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.8,
            zIndex: 1,
          }}
        />

        {/* Card content positioned at the bottom */}
        <CardContent
          sx={{
            // opacity:1,
            position: "relative",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 20,
            color: "black", 
            // padding: "0px", 
            backgroundColor: "#fff2df70"
          }}
        >
          <Typography
            variant="h6"
            fontFamily="Roboto-Serif"
            sx={{
              fontSize: { xs: "16px", sm: "22px" },
              fontWeight: "600",
            }}
          >
            {projects.projectsTitle}
          </Typography>
          <Typography
            variant="subtitle1"
            fontFamily="Roboto-Serif"
            sx={{
              fontSize: { xs: "14px", sm: "18px" },
              fontWeight: "500",
            }}
          >
            {projects.projectsLocation}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ProjectsCard;
