import React from 'react'
import { Container } from "@mui/material";
import HeroSection from '../components/landing/hero_section/hero_section.components';
import Properties from '../components/properties/properties.components';
import Services from '../components/services/services.components';
import Projects from '../components/projects/projects.components';
import AboutUs from '../components/about_us/about.components';
import Blogs from '../components/blogs/blogs.components';
import Testimonials from '../components/testimonials/testimonials.components';
const Landingpage = ()=>{
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                // border: "1px solid red"
                // backgroundColor: "yellow",
                // padding : "0px 10px"
            }}
        >
            <HeroSection />
            <Properties />
            <Services />
            <Projects />
            <AboutUs />
            <Blogs />
            <Testimonials />
        </Container>
    );
}
export default Landingpage;