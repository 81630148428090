import React from 'react';
import Grid from "@mui/material/Grid2";
import ServicesData from './services.data';
import ServicesCard from "./services.cards";
const ServicesCardListing = ()=>{
    return(
        <Grid container spacing={4}
            sx={{
                justifyContent: "center",
                height: "auto",
                pt:"20px",
                // backgroundColor: "skyblue"
            }}
        >
            {
                ServicesData.map((data,index)=>(
                    <ServicesCard services={data} key={index} />
                ))
            }
        </Grid>
    )
}
export default ServicesCardListing;