import React from 'react';
import { Container,Typography, Box } from "@mui/material";
import PremiumList from '../properties.cards.slider';
const PremiumListing = () => {
  return (
    <Container
        maxWidth={false}
        sx={{
            backgroundColor:"primary.sixth",
            padding: "0"
        }}
    >
        <Container
            sx={{
                display: "flex",
                flexDirection: {xs:"column"},
                justifyContent: "space-between",
                alignItems: "center",
                height: 'auto',
                // backgroundColor: "red",
                // border: "1px solid black",
                padding:{xs:"30px 10px",sm:"40px 0px",lg:"60px 0px"}
            }}
        >
            <Box
                sx={{
                width:{xs:"100%"},
                }}
            >
                <Typography variant="h6" fontWeight="500" gutterBottom textAlign="center"
                    sx={{
                        fontSize: {xs:"20px",sm:"24px",md:"32px",lg:"38px"},
                        color: "rgba(0,0,0,1)",
                        textTransform: "uppercase"
                        //   mt:{xs:"12px",sm:"20px"},
                    }}
                >
                    Premium Listing
                </Typography>
                <PremiumList/>
            </Box>
        </Container>
    </Container>
  );
};

export default PremiumListing;