import React from 'react';
import Slider from 'react-slick';
import BlogCard from './blogs.cards'; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogData from './blogs.Data'; 

const BlogList = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    arrows:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 2 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1, centerMode: true }
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }
    ]
  };

  return (
    <div className="center__carousel" style={{ marginBottom: '3rem' }}>
      <Slider {...settings}>
        {BlogData.map((blog) => (
          <BlogCard
            key={blog.id}
            title={blog.title}
            author={blog.author}
            date={blog.date}
            excerpt={blog.excerpt}
            image={blog.image}
          />
        ))}
      </Slider>
    </div>
  );
};

export default BlogList;
