import React from "react";
import {Card,Box, CardContent, CardMedia, Typography } from '@mui/material';
import Grid from "@mui/material/Grid2";
import { useNavigate } from 'react-router-dom';
const PropertiesCard = ({properties})=>{
  const navigate = useNavigate(); 
    const handleCardClick = () => {

        navigate(`/description/${properties.id}`);
    };
    return(
        <Grid item xs={12} sm={3} md={4} onClick={handleCardClick}>
              <Card
                sx={{
                    maxWidth:{xs:"340px",md:"260px",lg:"340px"}
                }}
              >
                <Box 
                    sx={{
                        height:"160px",
                        width: "100%",
                    }}
                >  
                    <CardMedia component="img" 
                        image={properties.propertyImage} 
                        alt={properties.propertyTitle} 
                        sx={{
                            backgroundColor: "skyblue",
                            height: "100%",
                            width: "100%",
                            backgroundPosition: "Center",
                            backgroundSize: "cover",
                            objectFit: "cover",
                            // padding: "20px 0px"
                        }}
                    />
                </Box>

                <CardContent>
                  <Typography variant="h6" fontFamily="Roboto-Serif"
                    sx={{
                        fontSize: {xs:"16px",sm:"22px"},
                        fontWeight: "600"
                    }}
                  >
                        {properties.propertyTitle}
                    </Typography>
                  <Typography variant="subtitle1" fontFamily="Roboto-Serif"
                    sx={{
                        fontSize: {xs:"14px",sm:"18px"},
                        fontWeight: "500"
                    }}
                  >
                        {properties.propertyLocation}
                    </Typography>
                  <Typography variant="body2" color="textSecondary" fontFamily="Times New Roman"
                    sx={{
                        fontSize: {xs:"12px",sm:"16px"},
                        textAlign: "justify"
                    }}
                >
                    {properties.propertyDescription.slice(0,100)} ...
                  </Typography>
                </CardContent>
              </Card>
        </Grid>
    )
}
export default PropertiesCard;