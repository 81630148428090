import React from "react";

import { Box, Container,Button } from "@mui/material";
import RightContainer from "./rightContainer";
import LeftContainer from "./leftContainer";
const AboutUs = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "primary.fifth",
        padding: "0",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column" },
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          padding: { xs: "30px 10px", sm: "40px 0px", lg: "60px 0px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%" },
          }}
        >
          
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
                // border: "1px solid red",
                // padding: "47px 20px 32px",
                gap: "3rem",
              }}
            >
              {/* Left Container */}
              <LeftContainer />
              {/* Right Container */}
              <RightContainer />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button
                    variant="outlined"
                    sx={{
                        color: "black",
                        borderColor: "secondary.main",
                        borderRadius: "0px 10px 0px 10px",
                        padding:"2px 10px",
                        "&:hover": {
                            borderColor: "secondary.main",
                        },
                    }}
                >
                    About Us
                </Button>
            </Box>
        </Box>
      </Container>
    </Container>
  );
};
export default AboutUs;