import React from 'react';
import Grid from "@mui/material/Grid2";
import PropertyData from './properties.data';
import PropertiesCard from "./property.cards";
const PropertiesCardListing = ()=>{
    return(
        <Grid container spacing={4}
        sx={{
            justifyContent: "center",
            height: "auto",
            pt:"20px",
            // backgroundColor: "skyblue"
          }}
        >
            {
                PropertyData.map((data,index)=>(
                    <PropertiesCard properties={data} key={index} />
                ))
            }
            
        </Grid>
    )
}
export default PropertiesCardListing;