const navItems = [
    {
      navItem:"Home",
      itemLink: "/"
    },
    {
      navItem:"Services",
      itemLink: "/services"
    },
    {
      navItem:"Projects",
      itemLink: "/projects"
    },{
      navItem:"Gallery",
      itemLink: "/gallery"
    },
    {
      navItem:"Blogs",
      itemLink: "/#blogs"
    },{
      navItem:"Request Property",
      itemLink: "/properties"
    },
  ];
  export default navItems;