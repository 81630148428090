 const TestimonialData=[
    {
        id: 1,
        name: "Santosh Bist",
        location: "Eakantakuna",
        post:"CEO",
        date:"May 1, 2024",
        feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },
    {
        id: 2,
        name: "Nabina Thapa",
        location: "Lagankhel",
        post:"Manager",
        date:"May 1, 2024",
        feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        id: 3,
        name: "Hena Miya",
        location: "Kusunti",
        post:"Manager",
        date:"May 1, 2024",
        feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        id: 4,
        name: "Samantha",
        location: "Kalanki",
        post:"Property Buyer",
        date:"May 1, 2024",
        feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },
    {
        id: 5,
        name: "Samima Miya",
        location: "Sanepa",
        post:"Manager",
        date:"May 1, 2024",
        feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        id: 6,
        name: "Sima Thapa",
        location: "Dhobighat",
        post:"Home Owner",
        date:"May 1, 2024",
        feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        id: 7,
        name: "Ashika Paudel",
        location: "Sanepa",
        post:"Manager",
        date:"October 1, 2020",
        feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
]
export default TestimonialData;