import React, { useState } from 'react';
import { Container, Typography, Button, Box, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import ProjectsListing from './projects.cards.listing';
import ProjectsData from './projects.data';

const Projects = () => {
  const [selectedStatus, setSelectedStatus] = useState('all');

  // Filter the projects based on the selected status
  const filteredProjects = ProjectsData.filter(project => {
    if (selectedStatus === 'all') return true;
    return project.projectsStatus === selectedStatus;
  });

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "primary.sixth",
        padding: "0"
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column" },
          justifyContent: "space-between",
          alignItems: "center",
          height: 'auto',
          padding: { xs: "30px 10px", sm: "40px 0px", lg: "60px 0px" }
        }}
      >
        <Box
          sx={{
            width: { xs: "100%" },
          }}
        >
          <Typography variant="h5" fontWeight="700" gutterBottom textAlign="center"
            sx={{
              fontSize: { xs: "24px", sm: "28px", md: "38px", lg: "48px" },
              color: "rgba(0,0,0,1)",
              textTransform: "uppercase"
            }}
          >
            Projects
          </Typography>
        </Box>

        {/* Radio buttons for filtering */}
        <Box
        >
          <RadioGroup row value={selectedStatus} onChange={handleStatusChange}>
            <FormControlLabel value="all" 
                control={<Radio sx={{transform: {xs:'scale(0.6)',sm:'scale(0.8'} }} />} 
                label={<Typography sx={{ fontSize: {xs:"12px",sm:"16px"} }}>All</Typography>} 
                sx={{
                    gap:0,
                    marginRight:{xs:"8px",sm:"24px"}
                }}    
            />
            <FormControlLabel value="completed" 
                control={<Radio sx={{ transform: {xs:'scale(0.6)',sm:'scale(0.8'} }} />} 
                label={<Typography sx={{ fontSize: {xs:"12px",sm:"16px"} }}>Completed</Typography>} 
                sx={{
                    gap:0,
                    marginRight:{xs:"8px",sm:"24px"}
                }}  
                />
            <FormControlLabel value="ongoing" 
                control={<Radio sx={{ transform: {xs:'scale(0.6)',sm:'scale(0.8'} }} />}
                label={<Typography sx={{ fontSize: {xs:"12px",sm:"16px"} }}>Ongoing</Typography>}
                sx={{
                    gap:0,
                    marginRight:{xs:"8px",sm:"24px"}
                }}  
            />
          </RadioGroup>
        </Box>

        {/* Pass filtered data to ProjectsListing */}
        <ProjectsListing ProjectsData={filteredProjects} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: { xs: "12px", sm: "20px" }
          }}
        >
          <Button
            variant="outlined"
            sx={{
              color: "rgba(0,0,0,0.7)",
              fontSize: { xs: "14px", md: "20px" },
              fontWeight: "400",
              textTransform: "capitalize",
              letterSpacing: "1px",
              padding: "0px 8px",
              border: "none",
              '&:hover': {
                transform: 'scale(1.2)',
                transition: '2s ease',
                backgroundColor: "transparent",
              }
            }}
          >
            See all
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default Projects;
