import React from 'react';
import { Box, Typography } from '@mui/material';

const ServiceDetailComponent = ({ serviceData }) => {
    console.log(serviceData);
    console.log("service Type:");
    console.log(serviceData.serviceOfferedUnder.map((data) => data)); // This will log the service types

    return (
        <Box
            sx={{
                // backgroundColor: "red",
                mt: 4
            }}
        >
            <Typography variant='h4' fontWeight="600" 
                sx={{
                    fontSize: { xs: "18px", sm: "28px", },
                }}
            >{serviceData.serviceTitle}</Typography>
            <Typography variant='body1' mb={1}>{serviceData.serviceDescription}</Typography>
            <Typography gutterBottom variant='v5' fontWeight="600" 
            
            sx={{
                fontSize: { xs: "14px", sm: "20px", },
            }}
            >Types</Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt:1,
                }}
            >
                {
                    serviceData.serviceOfferedUnder.map((sType, index) => {
                        // If sType is an object, handle it
                        if (typeof sType === 'object') {
                            return Object.entries(sType).map(([key, value], idx) => (
                                <Typography key={idx} variant=''>
                                    <span><b>{value[0]}</b></span>: {value[1]} {/* Assuming the structure like {type1: ["typeTitle", "Description too"]} */}
                                </Typography>
                            ));
                        }
                        // If sType is a simple string
                        return (
                            <Typography key={index}>
                                {sType}
                            </Typography>
                        );
                    })
                }
            </Box>
            
        </Box>
    )
}

export default ServiceDetailComponent;
