import React, { useState } from "react";
import { Box, FormControl, TextField, Button } from "@mui/material";

function DetailForm({ onSubmit }) {
  const [openRequestIcon, setOpenRequestIcon] = useState(false);

  // Handle the form submission
  const handleRequestForm = (e) => {
    e.preventDefault();
    setOpenRequestIcon(true);

    if (onSubmit) {
      onSubmit();
    }
  };
console.log(openRequestIcon)
  return (
    <Box
      sx={{
        padding: "20px",
        borderRadius: "20px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        maxWidth: "500px",
        margin: "10px auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <form onSubmit={handleRequestForm}>
        {/* Full Name Field */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            size="small"
            id="full-name"
            label="Full Name"
            variant="outlined"
            required
          />
        </FormControl>

        {/* Email Field */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            size="small"
            id="email"
            type="email"
            label="Email"
            variant="outlined"
            required
          />
        </FormControl>

        {/* Phone Number Field */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            size="small"
            id="phone-number"
            type="tel"
            label="Phone Number"
            variant="outlined"
            required
          />
        </FormControl>

        {/* Submit Button */}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "secondary.main",
              color: "white",
              padding: "10px 20px",
              borderRadius: "10px",
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default DetailForm;
