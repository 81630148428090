import React from "react";
import Intro from "../../assets/images/house.jpg";
import { Box, Typography } from "@mui/material";

const startYear = 2020;
const currentYear = new Date().getFullYear();
const yearCount = currentYear - startYear;
const LeftContainer = ()=>{
    return(
        <Box
                sx={{
                  minWidth: { xs: "100%", md: "40%" },
                  minHeight: { xs: "200px", md: "400px" },
                  // width: "100%",
                  // border: "1px solid black",
                //   padding: "40px",
                  background: "#fff",
                  backgroundImage: `url(${Intro})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* <img src={about} alt="About us" /> */}
                <Box
                  sx={{
                    // minWidth: { xs: "220px", md: "350px" },
                    // maxHeight: {xs: "260px",md: "450px"},
                    // width: "100%",
                    // border: "1px solid black",
                    backgroundColor: "rgba(238, 238, 238,0.8)",
                    // padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: { xs: 16, md: 30 },
                      color: "#000",
                      fontWeight: "bold",
                      fontFamily: "fontFamily",
                      backgroundImage: `url(${Intro})`,
                      WebkitBackgroundClip: "text",
                      backgroundSize: "cover",
                      backgroundPosition: "fit",
                      WebkitTextFillColor: "transparent",
                      mt: { xs: 1, md: 5 },
                    }}
                  >
                    Since {startYear}
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: { xs: 200, md: 400 },
                      color: "#000",
                      fontWeight: "bold",
                      fontFamily: "fontFamily",
                      WebkitTextStrokeWidth: "1px",
                      backgroundImage: `url(${Intro})`,
                      // background : "rgba(255,255,255,0.0)",
                      WebkitBackgroundClip: "text",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      WebkitTextFillColor: "transparent",
                      // border: "1px solid black",
                      display: "inline",
                      lineHeight: { xs: "140px", md: "300px" },
                    }}
                  >
                    {yearCount}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: { xs: 16, md: 30 },
                      color: "#000",
                      fontWeight: "bold",
                      fontFamily: "fontFamily",
                      backgroundImage: `url(${Intro})`,
                      WebkitBackgroundClip: "text",
                      backgroundSize: "cover",
                      backgroundPosition: "fit",
                      WebkitTextFillColor: "transparent",
                      mb: { xs: 1, md: 5 },
                      mt: { xs: 1, md: 5 },
                      textTransform: "uppercase",
                    }}
                  >
                    Years
                  </Typography>
                </Box>
              </Box>
    )
}
export default LeftContainer;